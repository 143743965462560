/*
 * @Date: 2023-02-27 03:01:31
 * @LastEditors: simLarry
 * @LastEditTime: 2023-05-03 19:45:14
 * @FilePath: \J1-M1-QRP-CODE\config\Host.config.js
 */
let Config = {};

export const DomainURL       = 'https://qrpm1stag.fun88.biz';

export const STAGE_CMS_URL   = 'https://cmsapistag.jbo88.biz';
export const PROD_CMS_URL    = 'https://cache.bhara88.com';
export const RROD_BAK_CMS_URL = 'https://cache.vsg2.com';
export const SL_CMS_URL      = 'https://cmsapisl.jbo88.biz';

export const HOST_URL_API         = 'https://j1-qrp-live-awstk.fubnb.com';
export const SL_HOST_URL_API      = 'https://j1-qrp-sl-awstk.fubnb.com';
export const STAGE_HOST_URL_API   = 'https://j1-qrp-stage-awstk.fubnb.com';

export const STAGE_ENPOINT_URL    = 'https://qrpstagingfun88.gamealiyun.com';
export const ENPOINT_URL          = 'https://qrpfun88.gamealiyun.com';

export const QRCODEURL            = 'https://shpfnkpf.funpo.com:2041';
export const BFFSC_URL = `https://febff-api-staging-m1-instance01.jbo88.biz`;
export const BFFSC_URLSL = `https://gatewaycnsl-scj1.jbo08.com`;
export const BFFSC_URLPD = `https://gatewaycn-scj1.jig68.com`;

// 判断环境
if ( typeof global.location !== 'undefined' ){
	let LocalHost = global.location.host;
	let isHttps = 'https:' === document.location.protocol;
	let SLAPI = Boolean(
		[
			'qrpm1sl.jbo88.biz',
			'127.0.0.1:8889',
			'localhost:8889'
		].find((v)=>global.location.href.includes(v))
	);
	
	// 测试环境
	let StagingAPI = Boolean(
		[
			'qrpm1stag.jbo88.biz'
		].find((v)=>global.location.href.includes(v))
	);
	
	if( StagingAPI ){
		
		Config = {
			HostApi   : STAGE_HOST_URL_API,
			LocalHost : '',
			CMSURL    : STAGE_CMS_URL,
			ENDPOINT  : STAGE_ENPOINT_URL,
			QRCODEURL : QRCODEURL,
			BFFSC_URL : BFFSC_URL,
		}
	}else if(SLAPI){
		// 判断是否在master 分支
		
		Config = {
			HostApi   : SL_HOST_URL_API,
			LocalHost : '',
			CMSURL    : SL_CMS_URL,
			ENDPOINT  : STAGE_ENPOINT_URL,
			QRCODEURL : QRCODEURL,
			BFFSC_URL : BFFSC_URLSL,

		}
	}else{
		// 判断是否在PROD 
		
		Config = {
			/* LIVE地址 */
			HostApi: HOST_URL_API,
			LocalHost: (isHttps ? 'https://' : 'http://') + LocalHost + '/',
			CMSURL: PROD_CMS_URL,
			ENDPOINT  : ENPOINT_URL,
			QRCODEURL : QRCODEURL,
			BFFSC_URL : BFFSC_URLPD,
		};
		
	}
		

} 

export default { Config };
